import React, { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import Helmet from "react-helmet";
import { ParallaxProvider } from "react-scroll-parallax";
import ListCard from "../components/list-card";
import Navbar from "../components/Navbar/";
import Footer from "../components/Footer/";

import { getVolumeData } from "../utils/subgraph";

const liquidityProviders = [
  {
    title: "Deposit your stable- or volatile asset into the ETHA eVAult",
    image: require("../assets/img/img1.png"),
    link: "https://app.ethalend.org/#vaults",
    target: "_blank",
    buttonLabel: "Use APP",
  },
  {
    title: "Automatically begin earning ETH, BTC and a boost in ETHA",
    image: require("../assets/img/img2.png"),
  },
  {
    title:
      "Rewards are harvested automatically once a day. Claim rewards at your convenience",
    image: require("../assets/img/img3.png"),
  },
  {
    title: "Withdraw your deposited tokens at any time",
    image: require("../assets/img/img4.png"),
  },
];
const ethaStakers = [
  {
    title: "Deposit any amount of ETHA tokens into the staking app",
    image: require("../assets/img/img5.png"),
    link: "https://app.ethalend.org/",
    target: "_blank",
    buttonLabel: "Stake",
  },
  {
    title: "Receive veETHA tokens in exchange for providing ETHA",
    image: require("../assets/img/img6.png"),
  },
  {
    title: "Receive a share of the total ETHA and protocol revenue",
    image: require("../assets/img/img7.png"),
  },
  {
    title: "Enjoy boosted voting and yield",
    image: require("../assets/img/img8.png"),
  },
];
const hybridLendingMarket = [
  {
    title: "Supply any amount of supported assets into the lending app",
    image: require("../assets/img/img9.png"),
    link: "#",
    buttonLabel: "Stake",
  },
  {
    title:
      "Wait a moments for the protocol to algorithmically optimize the best asset allocation for your assets",
    image: require("../assets/img/img10.png"),
  },
  {
    title:
      "Now execute your order with one click through your non-custodial ETHA Wallet in one transaction",
    image: require("../assets/img/img11.png"),
  },
  {
    title: "Enjoy optimized hybridyield",
    image: require("../assets/img/img12.png"),
  },
];

function IndexPage() {
  const [tabActive, setTabActive] = useState(0);
  const [show, setShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [volumeData, setVolumeData] = useState({
    totalVolume: 0,
    swapVolume: 0,
    weeklyVolume: 0,
    monthlyVolume: 0,
  });

  const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    });

    return formatter.format(value);
  };

  const handleTab = (i) => {
    setShow(false);
    setTabActive(i);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let hashUrl = window.location.hash;
    if (hashUrl) {
      let hashTarget = document.getElementById(hashUrl.replace("#", ""));
      window.scrollTo(0, hashTarget.getBoundingClientRect().top);
    }

    getVolumeData().then((t) => setVolumeData(t));

    setTimeout(() => {
      setPageLoaded(true);
    }, 100);
    setShow(true);
  }, []);

  return (
    <ParallaxProvider>
      <Helmet>
        <title>ETHA - The DeFi Yield Optimizer </title>
        <html lang="en" />
        <meta
          name="keywords"
          content="etha lend, ethalend, etha defi, blockchain lending platform, crypto defi, compound finance, dydx,
            decentralize lending, aave, yield optimizer, yearn finance, raricapital, etha crypto, etha blockchain, etharemit, ethalend, protocol
            agnostic, decentralized finance, ethereum lending platform, ethalend, web3
            defi"
        />
        <meta
          name="description"
          content="ETHA Lend is a protocol agnostic
            yield optimizer for the Decentralized Finance (DeFi) ecosystem built on Ethereum and Polkadot."
        />
        <meta
          property="twitter:image"
          content="https://i.ibb.co/9yDp6yD/etha-web.png"
        />

        <meta property="og:url" content="https://ethalend.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="ETHA Lend - The DeFi Yield
            Optimizer "
        />
        <meta
          property="og:description"
          content="ETHA Lend is a protocol
            agnostic yield optimizer for the Decentralized Finance (DeFi)
            ecosystem. built on Ethereum and Polkadot"
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/9yDp6yD/etha-web.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ETHA Lend - The DeFi Yield
            Optimizer "
        />
        <meta
          name="twitter:description"
          content="ETHA Lend is a protocol
            agnostic yield optimizer for the Decentralized Finance (DeFi)
            ecosystem built on Ethereum and Polkadot."
        />
      </Helmet>

      <div className={pageLoaded ? "app-main app-loaded" : "app-main"}>
        <Navbar isHomepage />
        <div className="header-full" id="pagetop">
          <div className="banner-inner">
            <div className="container">
              <div className="banner-caption">
                <Parallax speed={-8}>
                  <h1 className="heading-main load-effect from-right delay-1">
                    OPTIMIZED YIELD STRATEGIES
                  </h1>
                  <p className="load-effect from-right delay-2">
                    Deposit liquidity, and DCA your favourite digital assets
                  </p>
                  <div className="load-effect from-right delay-3">
                    <a
                      href="https://app.ethalend.org/"
                      target={"_blank"}
                      className="btn-main btn-primary"
                    >
                      Launch APP
                    </a>
                  </div>
                </Parallax>
              </div>
            </div>
          </div>

          <div className="facts-full">
            <div className="container">
              <div className="facts-box flex">
                <div className="fact-item flex-1">
                  <div className="fact-item-inner load-effect from-bottom delay-4">
                    <div className="heading">
                      <span>{formatCurrency(volumeData.totalVolume)}</span>
                    </div>
                    <div className="fact-label">Total Protocol Volume</div>
                  </div>
                </div>
                {/* <div className="fact-item flex-1">
                  <div className="fact-item-inner load-effect from-bottom delay-5">
                    <div className="heading">
                      <span>{formatCurrency(volumeData.monthlyVolume, 0)}</span>
                    </div>
                    <div className="fact-label">Protocol Volume 30d</div>
                  </div>
                </div> */}
                {/* <div className="fact-item flex-1">
                  <div className="fact-item-inner load-effect from-bottom delay-6">
                    <div className="heading">
                      <span>{formatCurrency(volumeData.weeklyVolume)}</span>
                    </div>
                    <div className="fact-label">Protocol Volume 7d</div>
                  </div>
                </div> */}
                <div className="fact-item flex-1">
                  <div className="fact-item-inner load-effect from-bottom delay-7">
                    <div className="heading">
                      <span>{formatCurrency(volumeData.swapVolume)}</span>
                    </div>
                    <div className="fact-label">Total Swap Volume</div>
                  </div>
                </div>
                <div className="fact-item flex-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-main load-effect from-bottom delay-8">
          <section className="section section-data">
            <div className="container">
              <div className="data-box">
                <div className="data-item flex">
                  <div className="data-item-left flex-1">
                    <div className="data-text">
                      <div className="heading sm">
                        <span>LIQUIDITY PROVIDERS</span>
                      </div>
                      <div className="title">Earn BTC, ETH and more</div>
                      <div className="para">
                        <p>
                          Deposit your favourite stable- or volatile asset and
                          automatically begin DCAing BTC, ETH and more.{" "}
                        </p>
                        <p>
                          Your principal is earning interest while you are
                          constantly enjoying rewards in your favourite asset.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="data-item-right flex-1">
                    <div className="data-img">
                      <div className="img-box img-box-1">
                        <div className="img-item">
                          <Parallax speed={-5}>
                            <img src={require("../assets/img/circle1_1.png")} />
                          </Parallax>
                        </div>
                        <div className="img-item">
                          <Parallax speed={5}>
                            <img src={require("../assets/img/circle1_2.png")} />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-item flex">
                  <div className="data-item-left flex-1">
                    <div className="data-text">
                      <div className="heading sm">
                        <span>ETHA STAKERS</span>
                      </div>
                      <div className="title">Put your ETHA to work</div>
                      <div className="para">
                        <p>
                          Receive veETHA by staking ETHA and earn boosted ETHA
                          rewards on top of protocol fees and voting rights.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="data-item-right flex-1">
                    <div className="data-img">
                      <div className="img-box img-box-2">
                        <div className="img-item">
                          <Parallax speed={-12}>
                            <img src={require("../assets/img/circle2_1.png")} />
                          </Parallax>
                        </div>
                        <div className="img-item">
                          <Parallax speed={-8}>
                            <img src={require("../assets/img/circle2_2.png")} />
                          </Parallax>
                        </div>
                        <div className="img-item">
                          <Parallax speed={-4}>
                            <img src={require("../assets/img/circle2_3.png")} />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="data-item flex">
                <div className="data-item-left flex-1">
                  <div className="data-text">
                    <div className="heading sm">
                      <span>Hybrid Lending Market</span>
                    </div>
                    <div className="title">
                      Algorithmically optimized asset allocation
                    </div>
                    <div className="para">
                      <p>
                        The discovery algorithm cost-efficiently reduces
                        fluctuating rates by utilizing a wide range of on-chain
                        data consequently, offering a hybrid supply rate model.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="data-item-right flex-1">
                  <div className="data-img App">
                    <div className="img-box img-box-3">
                      <div className="img-item">
                        <Parallax speed={4}>
                          <img
                            src={require("../assets/img/circle3_1.png")}
                          />
                        </Parallax>
                      </div>
                      <div className="img-item">
                        <Parallax speed={-8}>
                          <img
                            src={require("../assets/img/circle3_2.png")}
                          />
                        </Parallax>
                      </div>
                      <div className="img-item">
                        <Parallax speed={-4}>
                          <img
                            src={require("../assets/img/circle3_3.png")}
                          />
                        </Parallax>
                      </div>
                      <div className="img-item">
                        <Parallax speed={-4}>
                          <img
                            src={require("../assets/img/circle3_4.png")}
                          />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </section>
          <section className="section section-howitworks">
            <div className="container lg">
              <div className="heading-box text-center">
                <div className="heading2">HOW IT WORKS</div>
              </div>
              <ul className="tab-nav">
                <li
                  className={tabActive == 0 ? "active" : ""}
                  onClick={() => handleTab(0)}
                >
                  <div className="heading sm">
                    <span>Liquidity Providers</span>
                  </div>
                </li>
                <li
                  className={tabActive == 1 ? "active disabled" : "disabled"}
                  onClick={() => handleTab(1)}
                >
                  <div className="heading sm">
                    <span>ETHA Stakers</span>
                  </div>
                </li>
                {/* <li
                className={tabActive == 2 ? "active" : ""}
                onClick={() => handleTab(2)}
              >
                <div className="heading sm">
                  <span>Hybrid Lending Market</span>
                </div>
              </li> */}
              </ul>
              {tabActive == 0 && (
                <div
                  className={show ? "list-card-outer show" : "list-card-outer"}
                >
                  <ListCard data={liquidityProviders} />
                </div>
              )}
              {tabActive == 1 && (
                <div
                  className={show ? "list-card-outer show" : "list-card-outer"}
                >
                  <ListCard data={ethaStakers} />
                </div>
              )}
              {tabActive == 2 && (
                <div
                  className={show ? "list-card-outer show" : "list-card-outer"}
                >
                  <ListCard data={hybridLendingMarket} />
                </div>
              )}
            </div>
          </section>
          <section className="section section-audits" id="audit">
            <div className="container lg">
              <div className="container-box">
                <div className="heading3">Audits</div>
                <div className="audit-box flex">
                  <div className="audit-left">
                    <img src={require("../assets/img/certin-logo.png")} />
                  </div>
                  <div className="audit-right">
                    <div className="audit-data">
                      <p>
                        CertiK is the leading security-focused ranking platform
                        to analyze and monitor blockchain protocols and DeFi
                        projects.
                      </p>
                      <a
                        href="https://www.certik.com/projects/ethalend"
                        target={"_blank"}
                        className="link"
                      >
                        View the Report
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section section-rewards">
            <div className="container">
              <div className="container-inner">
                <div className="rewards-cta-box">
                  <div className="card text-center">
                    <div className="card-body">
                      <div className="heading md2">
                        <span>
                          The protocol is owned and governed by ETHA token
                          holders
                        </span>
                      </div>
                    </div>
                    <div className="card-footer">
                      <a
                        href="https://app.ethalend.org/governance"
                        target={"_blank"}
                        className="btn-main btn-primary"
                      >
                        Governance
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section section-partners" id="partners">
            <div className="container lg">
              <div className="container-box">
                <div className="heading-box">
                  <div className="heading2">PARTNERS</div>
                </div>
                <div className="investers-box">
                  <ul className="investers-list">
                    <li>
                      <a
                        href="https://chain.link/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/chainlink.png")}
                          alt="1"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dodoex.io/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img src={require("../assets/img/dodo.png")} alt="2" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://clv.org/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/clover.png")}
                          alt="3"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://quickswap.exchange/#/swap"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/quickswap.png")}
                          alt="4"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.gelato.network/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/gelato.png")}
                          alt="5"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.footprint.network/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/footprint.png")}
                          alt="6"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.konomi.network/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/konomi.png")}
                          alt="7"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://cryption.network/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img
                          src={require("../assets/img/cryption.png")}
                          alt="8"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="section section-backers" id="backers">
            <div className="container">
              <div className="container-box">
                <div className="heading-box">
                  <div className="heading2">BACKERS</div>
                </div>
                <div className="investers-box">
                  <ul className="investers-list backers-list">
                    <li>
                      <a
                        href="https://trgc.io/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img src={require("../assets/img/trgc.png")} alt="1" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.dfg.group/"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img src={require("../assets/img/dfg.png")} alt="2" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.okx.com/blockdream-ventures"
                        target={"_blank"}
                        className="invester-item"
                      >
                        <img src={require("../assets/img/okx.png")} alt="3" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </ParallaxProvider>
  );
}
export default IndexPage;
