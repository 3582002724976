import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { links } from "../menu-item";

function Navbar(props) {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    let winScroll = window.pageYOffset;
    winScroll > 50 ? setSticky(true) : setSticky(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <nav className={sticky ? "navbar-full sticky" : "navbar-full"}>
      <div className="navbar-inner">
        <div className="navbar flex">
          <div className="navbar-left">
            <a href={"#pagetop"} className="logo">
              <img src={require("../../assets/img/logo.svg")} alt="ETHA LEND" />
            </a>
          </div>
          <div className="navbar-middle flex-auto">
            <ul className="nav-main">
              {links.map((item, i) => {
                return (
                  <li key={i}>
                    <a href={item.link}>{item.text}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="navbar-right flex">
            <a
              href="https://app.ethalend.org/"
              target={"_blank"}
              className="btn-main btn-primary"
            >
              Launch APP
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
