import React, { useState } from "react";

function ListCard(props) {
  const [imgLoaded, setImgLoaded] = useState(false);
  return (
    <div className="list-card-box flex">
      {props.data?.map((item, i) => {
        return (
          <div className="list-card flex-1" key={i}>
            <div className="card">
              <div className="card-img">
                <div className="list-step">STEP </div>
                <div
                  className={imgLoaded ? "card-img-box loaded" : "card-img-box"}
                >
                  <div className="img-loading"></div>
                  <img
                    src={item.image}
                    onLoad={() =>
                      setTimeout(() => {
                        setImgLoaded(true);
                      }, 100)
                    }
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="list-info">{item.title}</div>
              </div>
              <div className="card-footer">
                {item.link && (
                  <a
                    href={item.link}
                    target={item.target ? item.target : ""}
                    className="btn-main btn-primary"
                  >
                    {item.buttonLabel}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default ListCard;
