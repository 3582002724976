import { GraphQLClient } from "graphql-request";
import gql from "graphql-tag";

const client = new GraphQLClient(
  "https://api.thegraph.com/subgraphs/name/wafflemakr/etha-lend"
);

const DAILY_DATA = (startDate) => {
  const queryString = `
    query  {
			dailyDatas(first: 500, where:{startDate_gte:${startDate}}) {
				id
				startDate
				symbol
				address
				type
				totalUnderlying
				totalVolumeUSD
			}
			
		}`;
  return gql(queryString);
};

const GLOBAL_DATA = () => {
  const queryString = `
    query {
			globalDatas(first:500){
				symbol
				address
				type
				totalUnderlying
				totalUnderlyingUSD
				totalVolumeUSD
			}
			globalSmartWalletDatas(first:5){
				totalSmartWallets
			}
		}`;
  return gql(queryString);
};

export const getVolumeData = async () => {
  try {
    const { globalDatas } = await client.request(GLOBAL_DATA());

    const totalVolume = globalDatas.reduce(
      (a, b) => a + Number(b.totalVolumeUSD),
      0
    );
    const swapVolume = globalDatas
      .filter((t) => t.type === "swap")
      .reduce((a, b) => a + Number(b.totalVolumeUSD), 0);

    const now = Math.floor(Date.now() / 1000);
    const { dailyDatas } = await client.request(
      DAILY_DATA(now - 30 * 24 * 60 * 60)
    );

    const weeklyVolume = dailyDatas
      .filter((t) => +t.startDate >= now - 7 * 24 * 60 * 60)
      .reduce((a, b) => a + Number(b.totalVolumeUSD), 0);

    const monthlyVolume = dailyDatas.reduce(
      (a, b) => a + Number(b.totalVolumeUSD),
      0
    );

    // console.log({ totalVolume, swapVolume, weeklyVolume, monthlyVolume });

    return { totalVolume, swapVolume, weeklyVolume, monthlyVolume };
  } catch (error) {
    console.log(error);
  }
};
