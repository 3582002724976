import React, { useEffect, useState } from "react";
import SocialIcons from "../social-icons";

const socialData = [
  {
    title: "twitter",
    link: "https://twitter.com/ethalend",
    icon: require("../../assets/img/twitter.svg"),
  },
  {
    title: "discord",
    link: "https://discord.gg/Njwzw7b7F2",
    icon: require("../../assets/img/discord.svg"),
  },
  {
    title: "send",
    link: "https://t.me/ethalendcommunity",
    icon: require("../../assets/img/send.svg"),
  },
  {
    title: "reddit",
    link: "https://www.reddit.com/r/ETHA/",
    icon: require("../../assets/img/reddit.svg"),
  },
];
function Footer() {
  const [scrollTopActive, setScrollTopActive] = useState(false);

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const viewportHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    if (window.pageYOffset > viewportHeight / 2) {
      setScrollTopActive(true);
    } else {
      setScrollTopActive(false);
    }
  };
  return (
    <>
      <footer className="footer-main">
        <div className="footer-inner">
          <div className="footer-box flex">
            <div className="f-col f-col-1 flex-4">
              <div className="f-col-group">
                <SocialIcons items={socialData} />
              </div>
            </div>
            <div className="f-col f-col-2 flex-3">
              <ul className="f-nav">
                <li>
                  <a href="https://docs.ethalend.org/etha" target={"_blank"}>
                    Tokenomics
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.ethalend.org/networks-and-codebase"
                    target={"_blank"}
                  >
                    Contract Addresses
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.ethalend.org/supported-assets"
                    target={"_blank"}
                  >
                    Supported Assets
                  </a>
                </li>
              </ul>
            </div>
            <div className="f-col f-col-3 flex-3">
              <ul className="f-nav">
                <li>
                  <a href="https://github.com/ethalend" target={"_blank"}>
                    GitHub
                  </a>
                </li>
                <li>
                  <a href="https://docs.ethalend.org/" target={"_blank"}>
                    GitBook
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/etha" target={"_blank"}>
                    Medium
                  </a>
                </li>
              </ul>
            </div>
            <div className="f-col f-col-4 flex-1">
              <ul className="f-nav">
                <li>
                  <a
                    href="https://github.com/ethalend/Brand-Assets"
                    target={"_blank"}
                  >
                    Branding
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/etha" target={"_blank"}>
                    Blog
                  </a>
                </li>
                <li>
                  <a href="https://t.me/ethalendcommunity" target={"_blank"}>
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className={`backtop-btn-outer ${scrollTopActive && "active"}`}>
        <span className="backtop-btn" onClick={handleScrollTop}></span>
      </div>
    </>
  );
}
export default Footer;
