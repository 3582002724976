import React from "react";

function SocialIcons(props) {
  return (
    <>
      <ul className="social-nav">
        {props.items?.map((item, i) => {
          return (
            <li key={i} className={`social-item-${item.title}`}>
              <a href={item.link} target={"_blank"}>
                <img src={item.icon} />
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
}
export default SocialIcons;
